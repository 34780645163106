/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Barcelone"), React.createElement("p", null, "Pour se loger, commencez par repérer ", React.createElement("a", {
    href: "https://www.booking.com/district/es/barcelona/ramblas.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "La Rambla"), ", avenue touristique de Barcelone, qui part du ", React.createElement("a", {
    href: "https://www.booking.com/landmark/es/port-vell.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "vieux port"), " et de la colonne Christophe Colomb pour rejoindre la ", React.createElement("a", {
    href: "https://www.booking.com/landmark/es/plaza-catalunya.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "place de Catalogne"), "."), React.createElement("p", null, "Ensuite, on peut dire que Barcelone s’articule en trois zones :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Le centre englobe le quartier de ", React.createElement("a", {
    href: "https://www.booking.com/district/es/barcelona/ramblas.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "las Ramblas"), ", mais aussi les rues médiévales du ", React.createElement("a", {
    href: "https://www.booking.com/district/es/barcelona/gotic.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "barri Gòtic"), " et le quartier branché ", React.createElement("a", {
    href: "https://www.booking.com/district/es/barcelona/raval.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "El Raval"), "."), "\n", React.createElement(_components.li, null, "Au nord, on trouve ", React.createElement("a", {
    href: "https://www.booking.com/district/es/barcelona/eixample.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "l’Eixample"), ", la ", React.createElement("a", {
    href: "https://www.booking.com/district/es/barcelona/sagrada-familia.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Sagrada Familia"), " et le ", React.createElement("a", {
    href: "https://www.booking.com/landmark/es/parque-guell.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "parc Güell"), "."), "\n", React.createElement(_components.li, null, "En bord de mer, ", React.createElement("a", {
    href: "https://www.booking.com/district/es/barcelona/barceloneta.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Barceloneta"), " et ", React.createElement("a", {
    href: "https://www.booking.com/district/es/barcelona/vila-olimpica.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vila Olímpica"), " sont les deux principaux quartiers."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
